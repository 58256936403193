import GooglePlayImg from 'assets/images/google-play.png'
import AppStoreImg from 'assets/images/app-store.png'
import { URLS } from 'shared/constants'

import Widget, { AppsGroup } from 'components/Widgets/Widget'

const apps = [
  {
    component: GooglePlayImg,
    alt: 'Logo Google Play',
    url: URLS.ANDROID,
    title: 'Pobierz aplikację Lastminuter na Android',
  },
  {
    component: AppStoreImg,
    alt: 'Logo App Store',
    url: URLS.IOS,
    title: 'Pobierz aplikację Lastminuter na iPhone',
  },
]

const MobileApps = ({ ...rest }) => (
  <AppsGroup apps={apps} imgClassName="apps__mobile_apps" {...rest}>
    Pobierz naszą <em>aplikację mobilną</em>
  </AppsGroup>
)

const MobileAppsWidget = () => (
  <Widget background="blue">
    <MobileApps />
  </Widget>
)

export { MobileApps }

export default MobileAppsWidget
