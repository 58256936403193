import BrowserDetector from 'browser-dtector'

export const formatDateDDMMYYYY = (date) => {
  // converts date to 'dd/mm/yyyy'
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  let d = date.getDate()

  if (d < 10) d = '0' + d
  if (m < 10) m = '0' + m

  return `${d}/${m}/${y}`
}

export const formatDateYYYYMMDD = (dateStr) => {
  // converts 'dd/mm/yyyy' to 'yyyy-mm-dd'
  const [d, m, y] = dateStr.split('/')
  return `${y}-${m}-${d}`
}

export const formatDateTime = (date) => {
  // converts datetime to 'dd/mm/yyyy hh:mm'
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  let d = date.getDate()

  let h = date.getHours()
  let min = date.getMinutes()

  if (d < 10) d = '0' + d
  if (m < 10) m = '0' + m
  if (min < 10) min = '0' + min
  if (h < 10) h = '0' + h

  return `${d}/${m}/${y} ${h}:${min}`
}

export const formatPrice = (num, addZl = false) => {
  // adds space separator after every 3rd digit
  // and optionally 'zł' at the end
  let res = ''
  const priceStr = Math.abs(num).toString()
  for (let i = 1; i < priceStr.length + 1; i++) {
    const n = priceStr[priceStr.length - i]
    res = n + res
    if (i % 3 === 0) res = ' ' + res
  }

  return `${num < 0 ? '-' : ''}${res.trim()}${addZl ? ' zł' : ''}`
}

export const getLocalStorageData = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const setLocalStorageData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const clearLocalStorageData = (key) => {
  localStorage.removeItem(key)
}

export const pluralizeGwiazdka = (count) => {
  if (+count >= 5) return 'gwiazdek'
  if (+count === 1) return 'gwiazdka'

  return 'gwiazdki'
}

const browserShortNames = {
  'Google Chrome': 'Chrome',
  'Mozilla Firefox': 'Firefox',
}

export const getBrowserName = () => {
  const browser = new BrowserDetector(window.navigator.userAgent)
  const info = browser.getBrowserInfo()

  if (!info) return null

  return browserShortNames[info.name] || info.name
}

export const getDaysSinceNow = (date) => {
  const now = new Date()
  const timeDifference = now.getTime() - date.getTime()
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  return daysDifference
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const sleep = (milliseconds) =>
  new Promise((resolve, _) => setTimeout(resolve, milliseconds))

export const pushApiSupported = () =>
  'PushManager' in window &&
  'serviceWorker' in navigator &&
  !!navigator.serviceWorker &&
  !!navigator.userAgent &&
  navigator.userAgent.indexOf('iPhone') === -1
